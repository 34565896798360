exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-find-us-js": () => import("./../../../src/pages/find-us.js" /* webpackChunkName: "component---src-pages-find-us-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sunset-santorini-js": () => import("./../../../src/pages/sunset-santorini.js" /* webpackChunkName: "component---src-pages-sunset-santorini-js" */),
  "component---src-pages-taste-js": () => import("./../../../src/pages/taste.js" /* webpackChunkName: "component---src-pages-taste-js" */)
}

